import React, { createContext, useContext, useState, useEffect } from "react";
import { useRouter } from "next/router";
import axios from 'axios';

const CreateContext = createContext();

export const useAppContext = () => useContext(CreateContext);

const Context = ({ children }) => {
  const router = useRouter();
  const [mobile, setMobile] = useState(true);
  const [rightBar, setRightBar] = useState(true);
  const [toggleTop, setToggle] = useState(true);
  const [toggleAuth, setToggleAuth] = useState(true);
  const [showItem, setShowItem] = useState(true);
  const [activeMobileMenu, setActiveMobileMenu] = useState(true);

  const [chats, setChats] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [activeAssistant, setActiveAssistant] = useState(null);
  const [publicAssistantsData, setPublicAssistantsData] = useState([]);
  const [userAssistants, setUserAssistants] = useState([]);

  const fetchExternalBaseUrl = async () => {
    const response = await fetch("/data/nisus-api.json");
    const data = await response.json();
    return data.external_base_endpoint;
  };

  const fetchBaseUrl = async () => {
    const response = await fetch("/data/nisus-api.json");
    const data = await response.json();
    return data.base_endpoint;
  };

  const fetchSessionId = async (baseUrl) => {
    try {
      let endpoint = `${baseUrl}/ExternalChatData/GetNewExternalChatSessionId`;
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`API error: ${response.statusText}`);
      }
      const data = await response.json();
      return data.sessionId;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const addChat = async () => {
    try {
      const baseUrl = await fetchExternalBaseUrl();
      const sessionId = await fetchSessionId(baseUrl);
      const newChat = { id: Date.now(), title: "New Chat", messages: [], sessionId };
      setChats((prevChats) => [...prevChats, newChat]);
      setActiveChat(newChat.id);
    } catch (error) {
      alert(`Failed to start a new chat: ${error.message}`);
    }
  };

  const deleteChat = (id) => {
    setChats((prevChats) => prevChats.filter((chat) => chat.id !== id));
    if (activeChat && activeChat === id) {
      setActiveChat(null);
    }
  };

  const renameChat = (id, newName) => {
    setChats((prevChats) =>
      prevChats.map((chat) =>
        chat.id === id ? { ...chat, title: newName } : chat
      )
    );
  };

  const sendMessage = async (chatId, message) => {
    const currentChat = chats.find(chat => chat.id === chatId);
    if (!currentChat) return;

    const newMessage = { text: message, author: "You" };
    setChats((prevChats) =>
      prevChats.map((chat) =>
        chat.id === chatId
          ? { ...chat, messages: [...chat.messages, newMessage] }
          : chat
      )
    );

    const baseUrl = await fetchExternalBaseUrl();
    const cancelTokenSource = axios.CancelToken.source();

    const loadingMessage = { text: "Generating answers for you…", author: "Bot", loading: true };
    setChats((prevChats) =>
      prevChats.map((chat) =>
        chat.id === chatId
          ? { ...chat, messages: [...chat.messages, loadingMessage] }
          : chat
      )
    );

    try {
      const response = await axios.post(`${baseUrl}/ExternalDeploymentCall`, {
        modelDeploymentEndpointId: activeAssistant.endpoint_id,
        message,
        chatSessionId: currentChat.sessionId
      }, { cancelToken: cancelTokenSource.token });

      const botResponse = { text: response.data.callResponse, author: "Bot", loading: false };
      setChats((prevChats) =>
        prevChats.map((chat) =>
          chat.id === chatId
            ? {
              ...chat,
              messages: chat.messages.map(msg =>
                msg.loading ? botResponse : msg
              )
            }
            : chat
        )
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.error('Error:', error);
      }
    }
  };

  const checkScreenSize = () => {
    if (window.innerWidth < 1200) {
      setMobile(false);
      setRightBar(false);
    } else {
      setMobile(true);
      setRightBar(true);
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    const { id } = router.query;
    fetch("/data/public-assistants.json")
      .then((response) => response.json())
      .then((data) => {
        setPublicAssistantsData(data);
        const defaultAssistant = data[0];
        const selectedAssistant = data.find((assistant) => assistant.id === id) || defaultAssistant;
        setActiveAssistant(selectedAssistant);
      });
  }, [router.query]);

  const addUserAssistant = (assistant) => {
    setUserAssistants((prev) => [...prev, assistant]);
  };

  const checkModelDeployment = async (endpoint_id) => {
    const baseUrl = await fetchBaseUrl();
    try {
      const response = await axios.get(`${baseUrl}/ModelDeploymentParameter`);
      if (response.data.modelDeploymentParameters.some(md => md.endpointId === endpoint_id)) {
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  const shouldCollapseLeftbar = !mobile;
  const shouldCollapseRightbar = !rightBar;

  return (
    <CreateContext.Provider
      value={{
        mobile,
        setMobile,
        showItem,
        setShowItem,
        activeMobileMenu,
        setActiveMobileMenu,
        toggleTop,
        setToggle,
        toggleAuth,
        setToggleAuth,
        rightBar,
        setRightBar,
        shouldCollapseLeftbar,
        shouldCollapseRightbar,
        chats,
        activeChat,
        addChat,
        deleteChat,
        renameChat,
        sendMessage,
        setActiveChat,
        activeAssistant,
        setActiveAssistant,
        publicAssistantsData,
        addUserAssistant,
        userAssistants,
        checkModelDeployment,
      }}
    >
      {children}
    </CreateContext.Provider>
  );
};

export default Context;
